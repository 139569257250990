import { useMemo } from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';

const defaultOptions = {
  threshold: 0.3,
};

export const useFuse = <T>(
  collection: T[],
  searchPhrase: string,
  options: IFuseOptions<T>
) => {
  const fuse = useMemo(
    () =>
      new Fuse(collection, {
        ...defaultOptions,
        ...options,
      }),
    [collection, options]
  );

  const results = useMemo(
    () =>
      searchPhrase
        ? fuse.search(searchPhrase).map((item) => item.item)
        : collection,
    [searchPhrase, collection, fuse]
  );

  return results;
};
