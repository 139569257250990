import { InternalAxiosRequestConfig } from 'axios';
import { HttpHeaders } from 'shared/common/consts';

export const attachSupportedUserIdInterceptor =
  (supportedUserId: string | null) => (request: InternalAxiosRequestConfig) => {
    if (!supportedUserId) {
      return request;
    }
    request.headers[HttpHeaders.ASSUME_USER_ID] = supportedUserId;
    return request;
  };
