import { InternalAxiosRequestConfig } from 'axios';
import { ApiNames } from 'shared/common/consts';
import { regionsMetadataStore } from 'shared/region-metadata/store';

type TApiNames = keyof typeof ApiNames;

const isBaseUrlReplaceable = (baseUrl: string): baseUrl is TApiNames =>
  Object.values(ApiNames).includes(baseUrl);

export const baseUrlInterceptor = async (request: InternalAxiosRequestConfig) => {
  const {
    apiUrls: { apiService, authService },
  } = regionsMetadataStore.getCurrentRegionMetadata();

  const apiNameUrlMap = {
    API: apiService,
    AUTH: authService,
  } as const;

  if (request.baseURL && isBaseUrlReplaceable(request.baseURL)) {
    request.baseURL = apiNameUrlMap[request.baseURL];
  }

  return request;
};
